import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv } from '@/utils/helpers';
import postNewTasq from '@/graphql/predictions/mutations/postNewTasq.graphql';
import updateStatus from '@/graphql/predictions/mutations/updateStatus.graphql';
import unsnoozeJob from '@/graphql/predictions/mutations/unsnoozeJob.graphql';
import reassignTasq from '@/graphql/workflow/mutations/reassignTasq.graphql';
import predictionsApolloClient from '@/lib/appsync/workflow';
import userResponsesApolloClient from '@/lib/appsync/workflow';
import postJobComment from '@/graphql/workflow/mutations/postJobComment.graphql';
import workflowApolloClient from '@/lib/appsync/workflow';
// import postGlobalWellComment from '@/graphql/workflow/mutations/postGlobalWellComment.graphql';
import tasqsListModule from '@/store/modules/tasqsListModule';
// import updateUserResponse from '@/graphql/userResponses/mutations/updateUserResponse';
import postJobResponseV2 from '@/graphql/userResponses/mutations/postJobResponseV2';
// import getProceduresStepsJson from '@/graphql/procedures/queries/getProceduresStepsJson.graphql';
import accountModule from './accountModule';
// import proceduresApolloClient from '@/lib/appsync/procedures';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'tasqActionsModule',
  store,
})
class TasqActionsModule extends VuexModule {
  @Action
  async addTasq({
    assetType,
    person,
    well,
    jobType,
    frequency,
    delayedDays,
    comment,
    stateChangeDate,
    stateChangeDateEnd,
  }) {
    try {
      const {
        data: {
          post_new_tasq: {
            id: predictionID,
            workflow_task_id: workflowTaskID,
          },
        },
      } = await predictionsApolloClient.mutate({
        mutation: postNewTasq,
        variables: {
          input: {
            level: assetType,
            assigned_to_user: typeof person === 'object' ? person.value : person,
            node_id: well,
            prediction_type: typeof jobType === 'object' ? jobType.value : jobType,
            ...stateChangeDate && {
              state_change_date: new Date(stateChangeDate).toISOString().slice(0, -5),
            },
            ...stateChangeDateEnd && {
              state_change_date_end: new Date(stateChangeDateEnd).toISOString().slice(0, -5),
            },
            ...frequency === 'Reoccurance' && {
              recurring_start_date: new Date().toISOString(),
              recurring_end_date: new Date(
                new Date().getTime() + 86400000 * delayedDays,
              ).toISOString(),
            },
            operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
      });
      if (jobType) {
        let jobTypeComment = jobType.key !== jobType.value ? `Job Type: ${jobType.key} - ${jobType.value}` : `Job Type: ${jobType.key}`;
        if (comment) {
          jobTypeComment = `${jobTypeComment} - Remarks: ${comment}`;
        }

        // const {
        //   data: {
        //     post_job_comment: {
        //       comment_id: jobCommentId,
        //     },
        //   },
        // } = await workflowApolloClient.mutate({
        //   mutation: postJobComment,
        //   variables: {
        //     input: {
        //       prediction_id: predictionID,
        //       username: typeof person === 'object' ? person.value.toLowerCase() : person.toLowerCase(),
        //       comment: jobTypeComment,
        //       should_post_as_global_well_comment: false,
        //     },
        //   },
        // });

        if (comment) {
        
          const input = {
            NodeID: well,
            PredictionID: predictionID,
            CloseTasq: false,
            Operator: getConfigEnv('OPERATOR_LOWERCASED'),
            Username: accountModule.user.email.toLowerCase(),
            Validate: '',
            ValidateComment: comment,
            Systems: [],
            SystemsComment: '',
            Symptoms: [],
            SymptomsComment: '',
            Causes: [],
            CausesComment: '',
            ProceduresJSONVersion: '',
            Actions: [],
            ActionsComment: '',
            WorkflowTaskID: workflowTaskID,
          };

          await userResponsesApolloClient.mutate({
            mutation: postJobResponseV2,
            variables: {
              input,
            },
          });
        }

        // await workflowApolloClient.mutate({
        //   mutation: postGlobalWellComment,
        //   variables: {
        // 	  input: {
        //       NodeID: well,
        //       Level: assetType,
        //       Username: accountModule.user.email.toLowerCase(),
        //       JobType: jobType.key,
        //       Comment: jobTypeComment,
        //       Operator: getConfigEnv('OPERATOR_LOWERCASED'),
        //       PredictionID: predictionID,
        //       CommentID: jobCommentId,
        //       ResponseData: JSON.stringify(input),
        // 	  },
        //   },
        //   });
      }

      // if (comment) {
      //   await workflowApolloClient.mutate({
      //     mutation: postJobComment,
      //     variables: {
      //       input: {
      //         prediction_id: predictionID,
      //         username: typeof person === 'object' ? person.value : person,
      //         comment,
      //         should_post_as_global_well_comment: false,
      //       },
      //     },
      //   });
      // }
      return predictionID;
    } catch (e) {
      // showApplicationError('Error while leaving feedback');
      // router.push({ name: 'Tasqs' });
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  async postNewWellComment(comment){
    try {
      await workflowApolloClient.mutate({
        mutation: postJobComment,
        variables: {
          input: {
            prediction_id: tasqsListModule.activeTasq?.id,
            username:  accountModule.user.email.toLowerCase(),
            comment: comment,
            should_post_as_global_well_comment: true,
          },
        },
      });
    } catch (error) {
      console.log(error)
    }
  }

  @Mutation
  async mutateTasqReassign({comment, person, id}) {
    await workflowApolloClient.mutate({
      mutation: reassignTasq,
      variables: {
        input: {
          id: id,
          username: typeof person === 'object' ? person.value.toLowerCase() : person.toLowerCase(),
          comment,
          operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
  }

  @Action
  async reassignTasq(tasq) {
    const {
      person,
      comment,
    } = tasq;
    if (tasqsListModule.isBatchReassign && tasqsListModule.checkedTasqs) {
      for (let i = 0; i < tasqsListModule.checkedTasqs.length; i++) {
        // eslint-disable-next-line no-param-reassign
        tasq.id = tasqsListModule.checkedTasqs[i]
        // eslint-disable-next-line no-await-in-loop
        await this.mutateTasqReassign(tasq);
      }
    } else {
      // console.log(tasqsListModule.activeTasq)
      // eslint-disable-next-line no-param-reassign
      tasq.id = tasqsListModule.activeTasq?.id;
      await this.mutateTasqReassign(tasq);
    }
  }

  @Action
  async rejectTasq({
    reason,
  }) {
    await predictionsApolloClient.mutate({
      mutation: updateStatus,
      variables: {
        input: {
          PredictionID: tasqsListModule.activeTasq?.id,
          Rejected: {
            Status: true,
            Reason: reason,
            // operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        //   operator: getConfigEnv('OPERATOR_LOWERCASED'),
        },
      },
    });
  }

  @Mutation
  async mutateTasqUpdateStatus({reason, comment = '', date, id,
  }) {
    const newComment = `${reason} ${comment.length ? `-Remarks: ${comment}` : ''}`;
    await predictionsApolloClient.mutate({
      mutation: updateStatus,
      variables: {
        input: {
          PredictionID: id,
          //   Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          Snoozed: {
            Status: true,
            UntilDate: new Date(date).toISOString().slice(0, -5),
            WaitUntilReason: newComment,
			WaitStartDate: new Date().toISOString().slice(0, -5)
            // Operator: getConfigEnv('OPERATOR_LOWERCASED'),
          },
        },
      },
    });
  }

  @Action
  async updateTasqStatus(action) {
    if (tasqsListModule.isBatchReassign && tasqsListModule.checkedTasqs) {
      for (let i = 0; i < tasqsListModule.checkedTasqs.length; i++) {
        // eslint-disable-next-line no-param-reassign
        action.id = tasqsListModule.checkedTasqs[i]
        // eslint-disable-next-line no-await-in-loop
        await this.mutateTasqUpdateStatus(action);
      }
    } else {
      // eslint-disable-next-line no-param-reassign
      action.id = tasqsListModule.activeTasq?.id;
      await this.mutateTasqUpdateStatus(action);
    }
  }

  @Action
  async unsnoozeJob(id) {
    await predictionsApolloClient.mutate({
      mutation: unsnoozeJob,
      variables: {
          PredictionID: id,
      },
    });
  }
}

export default getModule(TasqActionsModule);
