import gql from 'graphql-tag';
import { getConfigEnv } from '@/utils/helpers';

const mutation = gql`
  mutation post_job_response_v2($input: PostJobResponseV2Input!) {
    post_job_response_v2 (input: $input) {
	ID
  }
  }
`;

export default mutation;
